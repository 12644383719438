@import url('./styles/animations.css');


* {
    box-sizing: border-box;
}
body {
    margin: 0;
    font-family:  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: overlay;
}

::-webkit-scrollbar {
    width: 14px;
}

::-webkit-scrollbar-thumb {
    background-color: #c0c0c0;
    border: 5px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

input {
    border-radius: 0;
}

input[type="search"] {
    -webkit-appearance: none;
}
.app {
    min-height: 100vh;
    padding-top: 90px;
    overflow: overlay;
}

@media screen and (max-width: 1200px) {
    .app {
        padding-top: 70px;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
    position: fixed;
    top: 0;
    height: 90px;
    width: 100%;
    display: flex;
    gap: 32px;
    background: #1B1B1B;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 1400px;
    align-items: center;
}

.header-mobile {
    display: none;
}

.header-left {
    display: flex;
    flex-direction: row;
    gap: 32px;
}

.header-pc {
    display: flex;
    flex-direction: row;
    gap: 32px;
}
.visible {
    opacity: 1;
    visibility: visible;
}

.hidden {
    opacity: 0;
    visibility: hidden;
}
.underline {
    border-bottom: 1px solid #E3E3E3 !important;
}

.underline-error {
    border-bottom: 1px solid #ff4828 !important;
}

@media screen and (max-width: 1480px) {
    .header-content {
        width: 100%;
        padding: 0 32px;
    }
}
@media screen and (max-width: 1100px) {
    .header {
        height: 70px;
    }

    .header-content {
        width: 100%;
        padding: 0 32px;
    }

    .header-pc {
        display: none;
    }

    .header-mobile {
        display: unset;
    }
}

@media screen and (max-width: 767px) {
    .header-content {
        width: 100%;
        padding: 0 16px;
    }
}
.invisible {
    opacity: 0 !important;
    visibility: hidden !important;
    display: none !important;
}

@media screen and (min-width:767px) and (max-width: 1168px) {
    .invisible {
        display: flex !important;
    }
}

.appBlocked {
    height: 100vh;
    overflow: hidden;
}

.rotate {
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
}
