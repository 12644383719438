@keyframes opacity1 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes height1 {
    0%  {
        transform: translateY(50px);
    }

    100% {
        transform: translateY(0);
    }
}
@keyframes height2 {
    0%  {
        transform: translateY(30px);
    }

    100% {
        transform: translateY(0);
    }
}
@keyframes width1 {
    0%  {
        width: 25%;
    }

    100% {
        width: 100%;
    }
}
@keyframes scale1 {
    0%  {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes translate1 {
    0%  {
        transform: translateY(30px);
    }

    100% {
        transform: translateY(0);
    }
}

.animation1 {
    animation: translate1 .5s ease, opacity1 1.3s ease;
}
.animation2 {
    animation: opacity1 .5s ease, opacity1 1.3s ease;
}
.animation3 {
    animation: translate1 .5s ease, opacity1 1.3s ease;
}
.animation4 {
    animation: translate1 .5s ease, opacity1 1.3s ease;
}
.animation5 {
    animation: translate1 .5s ease, opacity1 1.3s ease;
}