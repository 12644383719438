
.checkboxElement {
  border-radius: 4px;
  border: 2px solid #898F97;
  height: 20px;
  width: 20px;
  outline: none;
  appearance: none;
  -webkit-appearance: none;

  :focus {
    outline: none;
  }
}

.checkboxElement:checked {
  border: 2px solid #007AFF;
  background: #007AFF center no-repeat url("data:image/svg+xml;utf8,<svg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path d=' M13.2071 1.71309C13.5976 1.32256 13.5976 0.689399 13.2071 0.298875C12.8166 -0.0916497 12.1834 -0.0916497 11.7929 0.298875L5 7.09177L2.20711 4.29887C1.81658 3.90835 1.18342 3.90835 0.792893 4.29887C0.402369 4.6894 0.402369 5.32256 0.792893 5.71309L4.29289 9.21309C4.68342 9.60361 5.31658 9.60361 5.70711 9.21309L13.2071 1.71309Z' fill='white'/></svg>");
}
